// import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch, RootState, useSelector } from '../store';
// utils
import axios from 'src/utils/axios';
import { Wallet } from 'src/@types/wallet';
import { PATH_API } from 'src/routes/paths';

// ----------------------------------------------------------------------

type WalletState = {
  isLoading: boolean;
  error: boolean;
  wallets: Wallet[];
};

const initialState: WalletState = {
  isLoading: false,
  error: false,
  wallets: []
};

const slice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state: any) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state: any, action: { payload: any }) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET WALLETS
    getWalletsSuccess(state: any, action: any) {
      state.isLoading = false;
      state.wallets = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { onToggleFollow, deleteUser } = slice.actions;

// ----------------------------------------------------------------------

export function getWallets() {
  return async () => {
    try {
      const response = await axios.get(PATH_API.user.wallets);
      dispatch(slice.actions.getWalletsSuccess(response.data.wallets));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
