// utils
import { fData } from "src/utils/formatNumber";
import { date } from "yup/lib/locale";
// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  app: `app`,
  all: `all`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  view: `view`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2: `menu level 2`,
  menu_level_3: `menu level 3`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  category: `category`,
  price: `price`,
  settings: `settings`,
  logout: `logout`,
  overview: `overview`,
  income_resources: `income resources`,
  wallets: `wallets`,
  entities: `entities`,
  transactions: `transactions`,
  assets: `assets`,
  name: `name`,
  label: `label`,
  owner: `owner`,
  number: `number`,
  no_number: `no number`,
  add: `add`,
  add_new: `add new`,
  select:`select`,
  change: `change`,
  update: `update`,
  view_more: `view more`,
  exchange: `exchange`,
  date: `date`,
  amount: `amount`,
  status: `status`,
  cancel: `cancel`,
  delete: `delete`,
  not_set: `not set`,
  attachment: {
    attachment: `attachment`,
    remove_all: `remove all`,
    upload_files: `upload files`,
    drop_or_select_file: `drop or select file`,
    drop_files_here_or_click: `drop files here or click`,
    browse: `browse`,
    thorough_your_machine: `thorough your machine`
  },
  description: {
    description: `description`,
    add_description: `add description`,
    title: `title`,
    value: `value`
  },
  canvas: {
    canvas: `canvas`,
    canvases: `canvases`,
    your_canvases: `Your Canvases`,
    add_canvas: `add canvas`,
    create_new_canvas: `create new canvas`,
    update_photo: `update photo`,
    upload_photo: `upload photo`,
    no_canvas: `select canvas`
  },
  entity: {
    entity: `entity`,
    entities: `entities`,
    all_entities: `all entities`,
    your_entities: `your entities`,
    entity_name: `entity name`,
    new_entity: `new entity`,
    add_new_entity: `add new entity`,
    update_entity: `update entity`,
    no_entities: `no entities`,
    use_default: `use this entity as default`,
    no_default_entity: `no default entity`,
    default_entity: `default entity`
  },
  income_resource: {
    income_resource: `income resource`,
    income_resources: `income resources`,
    all_income_resources: `all income resources`,
    income_method: `income method`,
    income_unit: `income unit`,
    add_new_income_resource: `add new income resource`,
    new_income_resource: `new income resource`,
    update_income_resource: `update income resource`,
    no_income_resources: `no income resource`
  },
  income_transaction: {
    income_transactions: `income transactions`,
    income_transaction: `income transaction`,
    income_transaction_number: `income transaction number`,
    all_income_transactions: `all income transactions`,
    new_income_transaction: `new income transaction`,
    no_income_transactions: `no income transaction`,
    income_transaction_list: `income transaction list`,
    income_transaction_search_palceholder: `search client or income transaction number...`
  },
  spent_transaction: {
    spent_transactions: `spent transactions`,
    spent_transaction: `spent transaction`,
    spent_transaction_number: `spent transaction number`,
    all_spent_transactions: `all spent transactions`,
    new_spent_transaction: `new spent transaction`,
    no_spent_transactions: `no spent transaction`,
    spent_transaction_list: `spent transaction list`,
    spent_transaction_search_palceholder: `search client or spent transaction number...`
  },
  converted_transaction: {
    convert: `convert`,
    add_convert: `add convert`,
    add_convert_description: `add convert description`,
    origin_amount: `origin amount`,
    destination_amount: `destination amount`,
    exchange_rate: `exchange rate`,
    transaction_fee: `transaction fee`,
    fee_currency: `fee currency`
  },
  transaction: {
    overall: `overall`,
    paid: `paid`,
    unpaid: `unpaid`,
    overdue: `overdue`,
    service_type: `service type`,
    start_date: `start date`,
    end_date: `end date`,
    client: `client`,
    origin_name: `origin name`,
    destination_name: `destination name`,
    paid_date: `paid date`,
    to_be_paid_date: `to be paid date`,
    amount: `amount`,
    status: `status`,
    from: 'from',
    to: 'to',
    add_transaction_description: `add transaction description`,
  },
  expense: {
    expenses: `expenses`,
    all_expenses: `all expenses`,
    add_new_expense: `add new expense`,
    new_expense: `new expense`,
    update_expense: `update expense`,
    no_expenses: `no expense`
  },
  currency: {
    currency: `currency`,
    currencies: `currencies`,
    all_currencies: `all currencies`,
    add_new_currency: `add new currency`,
    new_currency: `new currency`,
    update_currency: `update currency`,
    no_currencies: `no currency`
  },
  asset: {
    asset: `asset`,
    assets: `assets`,
    all_assets: `all assets`,
    new_asset: `new asset`,
    add_new_asset: `add new asset`,
    update_asset:  `update asset`
  },
  wallet: {
    wallet: `wallet`,
    wallets: `wallets`,
    all_wallets: `all wallets`,
    your_wallets: `your wallets`,
    wallet_name: `wallet name`,
    new_wallet: `new wallet`,
    add_new_wallet: `add new wallet`,
    update_wallet: `update wallet`,
    no_wallets: `no wallets`,
    use_default: `use this wallet as default`,
    no_default_wallet: `no default wallet`,
    default_wallet: `default wallet`
  },
  chart: {
    chart: `chart`,
    transaction_type: `transaction type`,
    date: {
      date: `date`,
      today: `today`,
      this_week: `this week`,
      last_week: `last week`,
      this_month: `this month`,
      last_month: `last month`,
      this_year: `this year`,
      last_year: `last year`,
      custom: `custom`,
    },
  },

  breadcrumb: {
    dashboard: `dashboard`,
    user: `user`,
    income_transactions: `income transactions`,
    spent_transactions: `spent transactions`,
  },
  prompt: {
    prompt_default_message: `Are you sure want to delete?`,
    prompt_default_cancel: `no`,
    prompt_default_confirm: `yes`,
    prompt_delete_asset: `Are you sure you want to delete this asset?`,
    prompt_delete_entity: `Are you sure you want to delete this entity?`,
    prompt_delete_expense: `Are you sure you want to delete this expense?`,
    prompt_delete_income_resource: `Are you sure you want to delete this income resource?`,
    prompt_delete_wallet: `Are you sure you want to delete this wallet?`,
  },
  expenses: `Expenses`,
  incomes: `Incomes`,
  expense_mo: `Expense/mo`,
  income_mo: `Income/mo`,
  members: `Members`,
  monthly_transaction_overview: `Monthly Transaction Overview`,
};

export default en;
