// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

function apiPath(sublink: string): string{
  return `/api${sublink}`;
}

function apiDynamicPath(sublink: string, wildCard: string): string{
  return `/api${sublink}/${wildCard}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  canvas: '/canvas',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    overview: path(ROOTS_DASHBOARD, '/overview'),
  },
  assets: {
    root: path(ROOTS_DASHBOARD, '/assets'),
    new: path(ROOTS_DASHBOARD, '/assets/new')
  },
  wallets: {
    root: path(ROOTS_DASHBOARD, '/wallets')
  },
  entities: {
    root: path(ROOTS_DASHBOARD, '/entities')
  },
  expenses: {
    root: path(ROOTS_DASHBOARD, '/expenses')
  },
  incomeResources: {
    root: path(ROOTS_DASHBOARD, '/income-resources')
  },
  incomeTransactions: {
    root: path(ROOTS_DASHBOARD, '/income-transactions')
  },
  spentTransactions: {
    root: path(ROOTS_DASHBOARD, '/spent-transactions')
  },
  chart: {
    root: path(ROOTS_DASHBOARD, '/chart')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/OBEorYicjdbIT6P1YQTTK7/%5BPreview%5D-Minimal-Web.15.10.22?node-id=0%3A1';


export const PATH_API = {
  canvas: {
    create: apiPath('/canvas'),
    read: (wildCard: string) => apiDynamicPath('/canvas', wildCard),
    readAll: apiPath('/canvas/canvases'),
    update: apiPath('/canvas'),
    delete: (wildCard: string) => apiDynamicPath('/canvas', wildCard)
  },
  asset: {
    create: apiPath('/asset'),
    read: (wildCard: string) => apiDynamicPath('/asset', wildCard),
    readAll: apiPath('/asset/assets'),
    update: apiPath('/asset'),
    delete: (wildCard: string) => apiDynamicPath('/asset', wildCard),
  },
  user: {
    create: apiPath('/user'),
    read: (wildCard: string) => apiDynamicPath('/user', wildCard),
    find: (wildCard: string) => apiDynamicPath('/user/find', wildCard),
    exists: (wildCard: string) => apiDynamicPath('/user/exists', wildCard),
    readAll: apiPath('/user/users'),
    update: apiPath('/user'),
    delete: (wildCard: string) => apiDynamicPath('/user', wildCard),
    logout: apiPath('/user/logout'),
    canvas: (wildCard: string) => apiDynamicPath('/user/canvas', wildCard),
    assets: apiPath('/user/assets'),
    wallets: apiPath('/user/wallets')
  },
  wallet: {
    create: apiPath('/wallet'),
    read: (wildCard: string) => apiDynamicPath('/wallet', wildCard),
    readAll: apiPath('/wallet/wallets'),
    update: apiPath('/wallet'),
    delete: (wildCard: string) => apiDynamicPath('/wallet', wildCard)
  },
  entity: {
    create: apiPath('/entity'),
    read: (wildCard: string) => apiDynamicPath('/entity', wildCard),
    readAll: apiPath('/entity/entities'),
    update: apiPath('/entity'),
    delete: (wildCard: string) => apiDynamicPath('/entity', wildCard)
  },
  currency: {
    create: apiPath('/currency'),
    read: (wildCard: string) => apiDynamicPath('/currency', wildCard),
    readAll: apiPath('/currency/currencies'),
    update: apiPath('/currency'),
    delete: (wildCard: string) => apiDynamicPath('/currency', wildCard)
  },
  walletCategory: {
    create: apiPath('/wallet-category'),
    read: (wildCard: string) => apiDynamicPath('/wallet-category', wildCard),
    readAll: apiPath('/wallet-category/wallet-categories'),
    update: apiPath('/wallet-category'),
    delete: (wildCard: string) => apiDynamicPath('/wallet-category', wildCard)
  },
  expenseCategory: {
    create: apiPath('/expense-category'),
    read: (wildCard: string) => apiDynamicPath('/expense-category', wildCard),
    readAll: apiPath('/expense-category/expense-categories'),
    update: apiPath('/expense-category'),
    delete: (wildCard: string) => apiDynamicPath('/expense-category', wildCard)
  },
  expense: {
    create: apiPath('/expense'),
    read: (wildCard: string) => apiDynamicPath('/expense', wildCard),
    readAll: apiPath('/expense/expenses'),
    update: apiPath('/expense'),
    delete: (wildCard: string) => apiDynamicPath('/expense', wildCard)
  },
  incomeResourceCategory: {
    create: apiPath('/income-resource-category'),
    read: (wildCard: string) => apiDynamicPath('/income-resource-category', wildCard),
    readAll: apiPath('/income-resource-category/income-resource-categories'),
    update: apiPath('/income-resource-category'),
    delete: (wildCard: string) => apiDynamicPath('/income-resource-category', wildCard)
  },
  incomeResource: {
    create: apiPath('/income-resource'),
    read: (wildCard: string) => apiDynamicPath('/income-resource', wildCard),
    readAll: apiPath('/income-resource/income-resources'),
    update: apiPath('/income-resource'),
    delete: (wildCard: string) => apiDynamicPath('/income-resource', wildCard)
  },
  incomeTransaction: {
    create: apiPath('/income-transaction'),
    read: (wildCard: string) => apiDynamicPath('/income-transaction', wildCard),
    readAll: apiPath('/income-transaction/income-transactions'),
    update: apiPath('/income-transaction'),
    delete: (wildCard: string) => apiDynamicPath('/income-transaction', wildCard),
    canvasOverallTransactions: (wildCard: string) => apiDynamicPath('/income-transaction', wildCard),
  },
  spentTransaction: {
    create: apiPath('/spent-transaction'),
    read: (wildCard: string) => apiDynamicPath('/spent-transaction', wildCard),
    readAll: apiPath('/spent-transaction/spent-transactions'),
    update: apiPath('/spent-transaction'),
    delete: (wildCard: string) => apiDynamicPath('/spent-transaction', wildCard),
    canvasOverallTransactions: (wildCard: string) => apiDynamicPath('/spent-transaction', wildCard),
  },
  valueCategory: {
    create: apiPath('/value-category'),
    read: (wildCard: string) => apiDynamicPath('/value-category', wildCard),
    readAll: apiPath('/value-category/value-categories'),
    update: apiPath('/value-category'),
    delete: apiPath('/value-category')
  },
  chart: {
    nodes: (canvasId: number, transactiontype: "any" | "spent" | "income") => apiPath(`/chart/nodes?transactionType=${transactiontype}&canvasId=${canvasId}`),
    edges: (canvasId: number, transactiontype: "any" | "spent" | "income", fromDate?: number, toDate?: number) => apiPath(`/chart/edges?transactionType=${transactiontype}&canvasId=${canvasId}&fromDate=${fromDate}&toDate=${toDate}`),
  }
}