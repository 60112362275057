import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from 'src/utils/axios';
import { User } from '../../@types/user';

import auth0 from 'auth0-js';
import { AUTH0_API } from 'src/config';
import { PATH_API } from 'src/routes/paths';

const webAuth = new auth0.WebAuth({
  domain: AUTH0_API.domain || 'badie.us.auth0.com',
  clientID: AUTH0_API.clientId || '80LrzcPWksgdzqSWIroUCicMj6JYB5Tg',
});

// ----------------------------------------------------------------------

type UserState = {
  isLoading: boolean;
  error: boolean;
  errorMessage: string;
  users: User | null;
};

const initialState: UserState = {
  isLoading: false,
  error: false,
  errorMessage: '',
  users: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUsers() {
  return async () => {
    try {
      dispatch(slice.actions.startLoading());
      const accessToken = localStorage.getItem('accessToken');
      // Get user email from auth0
      accessToken && webAuth.client.userInfo(accessToken, async (_err, res) => {
        // Get UserID from Eboom database
        const userResponse = await axios.get(PATH_API.user.read(`${res?.nickname}@gmail.com`))
        const user = await userResponse.data;
        if(user.success === true) {
          dispatch(slice.actions.getUsersSuccess(user.user));
        } else {
          dispatch(slice.actions.hasError(user.message));
        }
      })
    } catch(error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
