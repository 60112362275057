// import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch, RootState, useSelector } from '../store';
// utils
import axios from 'src/utils/axios';
import { Asset } from 'src/@types/asset';
import { PATH_API } from 'src/routes/paths';

// ----------------------------------------------------------------------

type UserState = {
  isLoading: boolean;
  error: boolean;
  assets: Asset[];
};

const initialState: UserState = {
  isLoading: false,
  error: false,
  assets: []
};

const slice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ASSETS
    getAssetsSuccess(state, action) {
      state.isLoading = false;
      state.assets = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { onToggleFollow, deleteUser } = slice.actions;

// ----------------------------------------------------------------------

export function getAssets() {
  return async () => {
    try {
      const response = await axios.get(PATH_API.user.assets);
      dispatch(slice.actions.getAssetsSuccess(response.data.assets));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
