// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ar = {
  demo: {
    title: `Arabic`,
    introduction: `لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد. كان Lorem Ipsum هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر الميلادي ، عندما أخذت طابعة غير معروفة لوحًا من النوع وتدافعت عليه لعمل كتاب عينة. لقد نجت ليس فقط خمسة قرون ، ولكن أيضًا القفزة في التنضيد الإلكتروني ، وظلت دون تغيير جوهري. تم نشره في الستينيات من القرن الماضي بإصدار أوراق Letraset التي تحتوي على مقاطع Lorem Ipsum ، ومؤخرًا مع برامج النشر المكتبي مثل Aldus PageMaker بما في ذلك إصدارات Lorem Ipsum.`,
  },
  docs: {
    hi: `أهلا`,
    description: `تحتاج مساعدة؟ \n الرجاء مراجعة مستنداتنا`,
    documentation: `توثيق`,
  },
  app: `تطبيق`,
  all: `الكل`,
  user: `المستعمل`,
  list: `قائمة`,
  edit: `تعديل`,
  view: `رأي`,
  shop: `متجر`,
  blog: `مقالات`,
  post: `بريد`,
  mail: `بريد`,
  chat: `دردشة`,
  cards: `البطاقات`,
  posts: `المشاركات`,
  create: `يزيد`,
  kanban: `كانبان`,
  general: `جنرال لواء`,
  banking: `الخدمات المصرفية`,
  booking: `الحجز`,
  profile: `الملف الشخصي`,
  account: `الحساب`,
  product: `المنتوج`,
  invoice: `فاتورة`,
  details: `تفاصيل`,
  checkout: `الدفع`,
  calendar: `التقويم`,
  analytics: `التحليلات`,
  ecommerce: `التجارة الإلكترونية`,
  management: `إدارة`,
  menu_level_1: `مستوى القائمة 1`,
  menu_level_2: `مستوى القائمة 2`,
  menu_level_3: `مستوى القائمة 3`,
  item_disabled: `العنصر معطل`,
  item_label: `تسمية العنصر`,
  item_caption: `عنوان العنصر`,
  other_cases: `حالات اخرى`,
  item_by_roles: `عنصر حسب الأدوار`,
  only_admin_can_see_this_item: `يمكن للمسؤول فقط رؤية هذا العنصر`,
  category: `الفئة`,
  price: `سعر`,
  settings: `الإعدادات`,
  logout: `تسجيل خروج`,
  overview: `نظرة عامة`,
  income_resources: `كل موارد الدخل`,
  wallets: `محافظ`,
  entities: `جهات`,
  transactions: `المعاملات`,
  assets: `أصول`,
  name: `اسم`,
  label: `ضع الكلمة المناسبة`,
  owner: `صاحبة`,
  number: `رقم`,
  no_number: `لا يوجد رقم`,
  add: `يضيف`,
  add_new: `يضيف`,
  select:`تحديد`,
  change: `يتغيرون`,
  update: `تحديث`,
  view_more: `عرض المزيد`,
  exchange: `تبادل`,
  date: `تاريخ`,
  amount: `مقدار`,
  status: `الحالة`,
  cancel: `يلغي`,
  delete: `حذف`,
  not_set: `غير مضبوط`,
  attachment: {
    attachment: `حاجز`,
    remove_all: `حذف الكل`,
    upload_files: `تحميل الملفات`,
    drop_or_select_file: `قم بإسقاط الملف أو تحديده`,
    drop_files_here_or_click: `قم بإسقاط الملفات هنا أو انقر فوق`,
    browse: `تصفح`,
    thorough_your_machine: `شامل جهازك`
  },
  description: {
    description: `وصف`,
    add_description: `اضف وصفا`,
    title: `لقب`,
    value: `القيمة`
  },
  canvas: {
    canvas: `اللوحة القماشية`,
    canvases: `اللوحات`,
    your_canvases: `اللوحات الخاصة بك`,
    add_canvas: `أضف قماش`,
    create_new_canvas: `إنشاء لوحة قماش جديدة`,
    update_photo: `تحديث الصورة`,
    upload_photo: `حمل الصورة`,
    no_canvas: `حدد قماش`
  },
  entity: {
    entity: `كيان`,
    entities: `جهات`,
    all_entities: `كل الكيانات`,
    your_entities: `كل الكيانات`,
    entity_name: `اسم الكيان`,
    new_entity: `كيان جديد`,
    add_new_entity: `إضافة كيان جديد`,
    update_entity: `كيان التحديث`,
    no_entities: `لا توجد كيانات`,
    use_default: `استخدام هذا الكيان كافتراضي`,
    no_default_entity: `لا يوجد كيان افتراضي`,
    default_entity: `الكيان الافتراضي`
  },
  income_resource: {
    income_resource: `مصدر الدخل`,
    income_resources: `موارد الدخل`,
    all_income_resources: `كل موارد الدخل`,
    income_method: `طريقة الدخل`,
    income_unit: `وحدة الدخل`,
    add_new_income_resource: `إضافة مصدر دخل جديد`,
    new_income_resource: `مصدر دخل جديد`,
    update_income_resource: `تحديث موارد الدخل`,
    no_income_resources: `لا موارد الدخل`
  },
  income_transaction: {
    income_transactions: `الإيرادات`,
    income_transaction: `دخل`,
    income_transaction_number: `رقم الإيرادات`,
    all_income_transactions: `كل الدخل`,
    new_income_transaction: `دخل جديد`,
    no_income_transactions: `لا دخل`,
    income_transaction_list: `قائمة الدخل`,
    income_transaction_search_palceholder: `بحث العميل أو رقم الإيرادات...`
  },
  spent_transaction: {
    spent_transactions: `أنفقت المعاملات`,
    spent_transaction: `قضى الصفقة`,
    spent_transaction_number: `قضى رقم المعاملة`,
    all_spent_transactions: `جميع المعاملات التي تم إنفاقها`,
    new_spent_transaction: `صفقة جديدة مستهلكة`,
    no_spent_transactions: `لا توجد معاملة مستنفدة`,
    spent_transaction_list: `أنفقت قائمة المعاملات`,
    spent_transaction_search_palceholder: `بحث العميل أو أنفقت رقم المعاملة...`
  },
  converted_transaction: {
    convert: `يتحول`,
    add_convert: `أضف تحويل`,
    add_convert_description: `أضف وصف التحويل`,
    origin_amount: `كمية الأصل`,
    destination_amount: `مبلغ الوجهة`,
    exchange_rate: `سعر الصرف`,
    transaction_fee: `رسوم التحويل`,
    fee_currency: `عملة الرسوم`
  },
  transaction: {
    overall: `الجميع`,
    paid: `دفع`,
    unpaid: `غير مدفوع الأجر`,
    overdue: `متأخر`,
    service_type: `نوع الخدمة`,
    start_date: `تاريخ البدء`,
    end_date: `تاريخ الانتهاء`,
    client: `عميل`,
    origin_name: `الاسم الاصلي`,
    destination_name: `اسم الوجهة`,
    paid_date: `تاريخ المدفوعة`,
    to_be_paid_date: `ليتم دفعها التاريخ`,
    amount: `مقدار`,
    status: `الحالة`,
    from: 'من',
    to: 'إلى',
    add_transaction_description: `إضافة وصف الصفقة`,
  },
  expense: {
    expenses: `نفقات`,
    all_expenses: `كل التكاليف`,
    add_new_expense: `إضافة حساب جديد`,
    new_expense: `حساب جديد`,
    update_expense: `حساب التحديث`,
    no_expenses: `لا نفقة`
  },
  currency: {
    currency: `عملة`,
    currencies: `العملات`,
    all_currencies: `جميع العملات`,
    add_new_currency: `أضف عملة جديدة`,
    new_currency: `عملة جديدة`,
    update_currency: `تحديث العملة`,
    no_currencies: `لا عملة`
  },
  asset: {
    asset: `أصل`,
    assets: `أصول`,
    all_assets: `كل الأصول`,
    new_asset: `أصول جديدة`,
    add_new_asset: `إضافة أصول جديدة`,
    update_asset:  `تحديث الأصول`
  },
  wallet: {
    wallet: `محفظة جيب`,
    wallets: `محافظ`,
    all_wallets: `كل المحافظ`,
    your_wallets: `محافظك`,
    wallet_name: `اسم المحفظة`,
    new_wallet: `محفظة جديدة`,
    add_new_wallet: `إضافة محفظة جديدة`,
    update_wallet: `تحديث المحفظة`,
    no_wallets: `لم يتم العثور على محافظ`,
    use_default: `استخدم هذه المحفظة كإعداد افتراضي`,
    no_default_wallet: `لا توجد محفظة افتراضية`,
    default_wallet: `المحفظة الافتراضية`
  },
  chart: {
    chart: `جدول`,
    transactionType: `نوع المعاملة`,
    date: {
      date: `تاريخ`,
      today: `اليوم`,
      this_week: `.هذا الاسبوع`,
      last_week: `الاسبوع الماضى`,
      this_month: `هذا الشهر`,
      last_month: `الشهر الماضي`,
      this_year: `هذه السنة`,
      last_year: `العام الماضي`,
      custom: `العادة`,
    },
  },
  breadcrumb: {
    dashboard: `لوحة القيادة`,
    user: `المستعمل`,
    income_transactions: `الإيرادات`,
    spent_transactions: `أنفقت المعاملات`,
  },
  prompt: {
    prompt_default_message: `هل أنت متأكد من أنك تريد الحذف؟`,
    prompt_default_cancel: `رقم`,
    prompt_default_confirm: `نعم`,
    prompt_delete_asset: `هل أنت متأكد أنك تريد حذف هذا الأصل؟`,
    prompt_delete_entity: `هل أنت متأكد أنك تريد حذف هذا الكيان؟`,
    prompt_delete_expense: `هل أنت متأكد أنك تريد حذف هذه النفقات؟`,
    prompt_delete_income_resource: `هل أنت متأكد أنك تريد حذف مصدر الدخل هذا؟`,
    prompt_delete_wallet: `هل أنت متأكد أنك تريد حذف هذه المحفظة؟`,
  },
  expenses: `نفقات`,
  incomes: `الدخل`,
  expense_mo: `المصاريف / شهر`,
  income_mo: `الدخل / شهر`,
  members: `أعضاء`,
  monthly_transaction_overview: `نظرة عامة على المعاملات الشهرية`,
};

export default ar;
