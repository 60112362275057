// utils
import { fData } from "src/utils/formatNumber";
// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const fa = {
  demo: {
    title: `Persian`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `سلام`,
    description: `به کمک نیاز دارید؟ لطفا مستندات ما را بررسی کنید.`,
    documentation: `مستندات`,
  },
  app: `برنامه`,
  all: `همه`,
  user: `کاربر`,
  list: `فهرست`,
  edit: `ویرایش`,
  view: `مشاهده`,
  shop: `فروشگاه`,
  blog: `بلاگ`,
  post: `پست`,
  mail: `پست الکترونیک`,
  chat: `گفتگو`,
  cards: `کارت ها`,
  posts: `پست ها`,
  create: `ساخت`,
  kanban: `kanban`,
  general: `عمومی`,
  banking: `بانکداری`,
  booking: `رزرو`,
  profile: `پروفایل`,
  account: `حساب کاربری`,
  product: `محصول`,
  invoice: `صورتحساب`,
  details: `جزئیات`,
  checkout: `وارسی`,
  calendar: `تقویم`,
  analytics: `تجزیه و تحلیل`,
  ecommerce: `تجارت الکترونیک`,
  management: `مدیریت`,
  menu_level_1: `منو سطح 1`,
  menu_level_2: `منو سطح 2`,
  menu_level_3: `منو سطج 3`,
  item_disabled: `مورد غیرفعال شد`,
  item_label: `برچسب مورد`,
  item_caption: `عنوان مورد`,
  other_cases: `موارد دیگر`,
  item_by_roles: `مورد بر اساس نقش`,
  only_admin_can_see_this_item: `فقط مدیر می تواند این مورد را ببیند`,
  category: `دسته بندی`,
  price: `قیمت`,
  settings: `تنظیمات`,
  logout: `خروج`,
  overview: `نمای کلی`,
  income_resources: `منابع درآمد`,
  wallets: `کیف پول ها`,
  entities: `اشخاص/نهادها`,
  transactions: `معاملات`,
  assets: `دارایی ها`,
  name: `نام`,
  label: `برچسب`,
  owner: `مالک`,
  number: `شماره`,
  no_number: `بدون شماره`,
  add: `اضافه کردن`,
  add_new: `جدید اضافه کردن`,
  select:`انتخاب کردن`,
  change: `تغییر دادن`,
  update: `بروزرسانی`,
  view_more: `مشاهده بیشتر`,
  exchange: `تبادل`,
  date: `تاریخ`,
  amount: `مقدار`,
  status: `وضعیت`,
  cancel: `لغو`,
  delete: `حذف`,
  not_set: `تنظیم نشده`,
  attachment: {
    attachment: `پیوست`,
    remove_all: `حذف همه`,
    upload_files: `آپلود فایل`,
    drop_or_select_file: `فایل را رها یا انتخاب کنید`,
    drop_files_here_or_click: `فایل ها را اینجا رها کنید یا بر روی`,
    browse: `مرور کردن`,
    thorough_your_machine: `دستگاه کلید کنید`
  },
  description: {
    description: `توضیحات`,
    add_description: `اضافه کردن توضیحات`,
    title: `عنوان`,
    value: `مقدار`
    
  },
  canvas: {
    canvas: `کانواس`,
    canvases: `کانواس ها`,
    your_canvases: `کانواس های شما`,
    add_canvas: `کانواس جدید`,
    create_new_canvas: `ساخت کانواس جدید`,
    update_photo: `بروزرسانی عکس`,
    upload_photo: `آپلود عکس`,
    no_canvas: `انتخاب کانواس`
  },
  entity: {
    entity: `شخص/نهاد`,
    entities: `اشخاص/نهادها`,
    all_entities: `همه اشخاص/نهادها`,
    your_entities: `اشخاص/نهادهای شما`,
    entity_name: `اشخاص/نهادها شما`,
    new_entity: `شخص/نهاد جدید`,
    add_new_entity: `اضافه کردن شخص/نهاد جدید`,
    update_entity: `بروزرسانی شخص/نهاد`,
    no_entities: `بدون شخص/نهاد`,
    use_default: `از این شخص/نهاد به عنوان پیش فرض استفاده شود`,
    no_default_entity: `بدون شخص/نهاد پیش فرض`,
    default_entity: `شخص/نهاد پیش فرض`
  },
  income_resource: {
    income_resource: `منبع درآمد`,
    income_resources: `منابع درآمد`,
    all_income_resources: `تمام منابع درآمد`,
    income_method: `روش درآمد`,
    income_unit: `واحد درآمد`,
    add_new_income_resource: `اضافه کردن منبع درآمد جدید`,
    new_income_resource: `منبع درآمد جدید`,
    update_income_resource: `به روز رسانی منبع درآمد  `,
    no_income_resources: `بدون منابع درآمدی`
  },
  income_transaction: {
    income_transactions: `درآمد ها`,
    income_transaction: `درآمد`,
    income_transaction_number: `شماره درآمد`,
    all_income_transactions: `همه درآمد ها`,
    new_income_transaction: `درآمد جدید`,
    no_income_transactions: `بدون درآمد`,
    income_transaction_list: `لیست درآمدها`,
    income_transaction_search_palceholder: `جستجوی مشتری یا شماره درآمد...`
  },
  spent_transaction: {
    spent_transactions: `مخارج`,
    spent_transaction: `خرج`,
    spent_transaction_number: `شماره خرج`,
    all_spent_transactions: `همه مخارح`,
    new_spent_transaction: `خرج جدید`,
    no_spent_transactions: `بدون خرج`,
    spent_transaction_list: `لیست مخارج`,
    spent_transaction_search_palceholder: `جستجوی مشتری یا شماره خرج...`
  },
  converted_transaction: {
    convert: `تبدیل`,
    add_convert: `اضافه کردن تبدیل`,
    add_convert_description: `اضافه کردن توضیحات تبدیل`,
    origin_amount: `مبلغ مبدا`,
    destination_amount: `مبلغ مقصد`,
    exchange_rate: `قیمت ارز`,
    transaction_fee: `کارمزد تبدیل`,
    fee_currency: `ارز کارمزد`
  },
  transaction: {
    overall: `همه`,
    paid: `پرداخت شده`,
    unpaid: `پرداخت نشده`,
    overdue: `عقب افتاده`,
    service_type: `نوع خدمات`,
    start_date: `تاریخ شروع`,
    end_date: `تاریخ پایان`,
    client: `مشتری`,
    origin_name: `نام مبدا`,
    destination_name: `نام مقصد`,
    paid_date: `تاریخ پرداخت`,
    to_be_paid_date: `مهلت پرداخت`,
    amount: `مقدار`,
    status: `وضعیت`,
    from: 'از',
    to: 'به',
    add_transaction_description: `اضافه کردن توضیحات معامله`,
  },
  expense: {
    expenses: `مقاصد خرج`,
    all_expenses: `تمام مقاصد خرج`,
    add_new_expense: `اضافه کردن مقصد خرج جدید`,
    new_expense: `مقصد خرج جدید`,
    update_expense: `به روز رسانی مقصد خرج`,
    no_expenses: `بدون مقصد خرج`
  },
  currency: {
    currency: `واحد ارز`,
    currencies: `ارزها`,
    all_currencies: `همه ارزها`,
    add_new_currency: `اضافه کردن ارز جدید`,
    new_currency: `ارز جدید`,
    update_currency: `به روز رسانی ارز`,
    no_currencies: `بدون ارز`
  },
  asset: {
    asset: `دارایی`,
    assets: `دارایی ها`,
    all_assets: `همه دارایی ها`,
    new_asset: `دارایی جدید`,
    add_new_asset: `اضافه کردن دارایی جدید`,
    update_asset:  `بروزرسانی دارایی`
  },
  wallet: {
    wallet: `کیف پول`,
    wallets: `کیف پول ها`,
    all_wallets: `همه کیف پول ها`,
    your_wallets: 'کیف پول های شما',
    wallet_name: `نام کیف پول`,
    new_wallet: `کیف پول جدید`,
    add_new_wallet: 'اضافه کردن کیف پول جدید',
    update_wallet: `بروزرسانی کیف پول`,
    no_wallets: `بدون کیف پولی`,
    use_default: `این کیف پول به عنوان کیف پول پیش فرض استفاده شود`,
    no_default_wallet: `بدون کیف پول پیش فرض`,
    default_wallet: `کیف پول پیش فرض`
  },
  chart: {
    chart: `چارت`,
    transactionType: `نوع معامله`,
    date: {
      date: `تاریخ`,
      today: `امروز`,
      this_week: `این هفته`,
      last_week: `هفته گذشته`,
      this_month: `این ماه`,
      last_month: `ماه گذشته`,
      this_year: `امسال`,
      last_year: `سال گذشته`,
      custom: `سفارشی`,
    },
  },
  breadcrumb: {
    dashboard: `داشبورد`,
    user: `کاربر`,
    income_transactions: `درآمد ها`,
    spent_transactions: `مخارج`,
  },
  prompt: {
    default_prompt_message: `آیا مطمئن هستید که می خواهید حذف کنید؟`,
    prompt_default_cancel: 'خیر',
    prompt_default_confirm: 'بلی',
    prompt_delete_asset: `آیا از حذف این دارایی مطمئن هستید؟`,
    prompt_delete_entity: `آیا از حذف این شخص/نهاد مطمئن هستید؟`,
    prompt_delete_expense: `آیا از حذف این هزینه مطمئن هستید؟`,
    prompt_delete_income_resource: `آیا از حذف این منبع درآمد مطمئن هستید؟`,
    prompt_delete_wallet: `آیا از حذف این کیف پول مطمئن هستید؟`,
  },
  expenses: `هزینه ها`,
  incomes: `درآمد`,
  expense_mo: `هزینه/ماه`,
  income_mo: `درآمد/ماه`,
  members: `اعضا`,
  monthly_transaction_overview: `بررسی اجمالی تراکنش ماهانه`,
};

export default fa;
