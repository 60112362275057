import { createContext, useEffect, useReducer, useCallback } from 'react';
import auth0, { type WebAuth } from 'auth0-js';
// routes
import { PATH_API, PATH_AUTH } from '../routes/paths';
// config
import { AUTH0_API } from '../config';
//
import { ActionMapType, AuthStateType, AuthUserType, Auth0ContextType } from './types';
import jwt_decode from "jwt-decode";
import { useRouter } from 'next/router';
import { isValidToken, setSession } from './utils';
import axios from 'axios';
// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<Auth0ContextType | null>(null);

// ----------------------------------------------------------------------

const handleToken = (token: string, action: 'save' | 'read' | 'delete') => {
  switch (action) {
    case 'save':
      localStorage.setItem('accessToken', token);
      break;
    case 'read':
      return localStorage.getItem('accessToken');
    case 'delete':
      localStorage.removeItem('accessToken');
      break;
    default:
      break;
  }
  return '';
};

// ----------------------------------------------------------------------

// let auth0Client: Auth0Client | null = null;

let webAuth: WebAuth | null = null;

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const router = useRouter();

  const initialize = useCallback(async () => {
    try {
      webAuth = new auth0.WebAuth({
        clientID: AUTH0_API.clientId || '',
        domain: AUTH0_API.domain || '',
        redirectUri: window.location.origin
      });      


      const afterHash = window.location.href.split('#')[1];
      const params = afterHash?.split('&');
      const urlToken = params?.find((param) => param.includes('access'));
      let accessToken: string | null = urlToken?.split('access_token=')[1] || null;

      if(!accessToken && handleToken('', 'read')) accessToken = handleToken('', 'read');

      if (isValidToken(accessToken || '')) {
        setSession(accessToken);
        const decodedToken: any = accessToken && jwt_decode(accessToken);
        accessToken && webAuth?.client.userInfo(accessToken, async (err, res) => {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          if (err) throw err;
          const user = {
            email: decodedToken['https://example.com/email'],
            firstName: res.given_name,
            lastName: res.family_name,
            picture: res.picture,
            sub: res.sub
          };

          const userExists = await axios.get(PATH_API.user.exists(user.email), {
            headers: {
              "Authorization": `Bearer ${accessToken}`
            }
          });
          const userExistsRes = await userExists.data;

          if(!userExistsRes.success) {
            await axios.post(PATH_API.user.create, {
              "email": decodedToken['https://example.com/email'],
              "firstName": user.firstName,
              "lastName": user.lastName,
              "photoUrl": user.picture,
            }, {
              headers: {
                "Authorization": `Bearer ${accessToken}`
              }
            });
          }
          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: true,
              user: {
                ...res,
                email: decodedToken['https://example.com/email'],
                displayName: res.name,
                photoURL: user?.picture,
                role: 'admin',
              },
            },
          });
          router.push('/canvas');
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = async (email?: string, password?: string) => {
    // await webAuth?.loginWithPopup();

    // const isAuthenticated = await webAuth?.isAuthenticated();

    // if (isAuthenticated) {
    //   const user = await webAuth?.getUser();
    //   console.log('user', user)
    //   dispatch({
    //     type: Types.LOGIN,
    //     payload: {
    //       user: {
    //         ...user,
    //         displayName: user?.name,
    //         photoURL: user?.picture,
    //         role: 'admin',
    //       },
    //     },
    //   });
    // }

    // const parameters = {
    //   // username: email,
    //   email,
    //   password,
    //   realm: AUTH0_API.connection,
    //   responseType: 'id_token',
    // };
    // const response = await axiosInstance.post('/auth/get-token', {
    //   client_id: AUTH0_API.clientId,
    //   client_secret: AUTH0_API.clientSecret,
    //   grant_type: 'password',
    //   username: email,
    //   password,
    //   audience: AUTH0_API.audience,
    // })
    // const accessToken = response.data.access_token;
    // if(accessToken) {
    //   const decodedToken: any = jwt_decode(accessToken);
    //   webAuth?.login(parameters, (error, response) => {
    //     // eslint-disable-next-line @typescript-eslint/no-throw-literal
    //     if (error) throw error;
    //     webAuth?.client.userInfo(response.accessToken, (err, res) => {
    //     //   // eslint-disable-next-line @typescript-eslint/no-throw-literal
    //       if (err) throw err;
    //       const user = {
    //         email: decodedToken['https://example.com/email'],
    //         firstName: res.given_name,
    //         lastName: res.family_name,
    //         picture: res.picture,
    //         sub: res.sub
    //       };
    //       dispatch({ type: Types.LOGIN, payload: { user: user || null } });
    //     });
    //   });
    // }
  };

  // LOGOUT
  const logout = () => {
    webAuth?.logout({
      returnTo: '/canvas'
    });
    handleToken('', 'delete');
    window.location.href = PATH_AUTH.login;
    dispatch({
      type: Types.LOGOUT,
    });
  };

  const loginWithGoogle = async () => {
    webAuth?.authorize({
      connection: 'google-oauth2',
      responseType: 'token',
      scope: 'openid profile',
      redirectUri: `${window.location.origin}/auth/login`
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'auth0',
        login,
        logout,
        loginWithGoogle,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
