// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const cn = {
  demo: {
    title: `Chinese`,
    introduction: `Lorem Ipsum 只是印刷和排版行业的虚拟文本。 自 1500 年代以来，Lorem Ipsum 一直是行业的标准虚拟文本，当时一位不知名的印刷商采用了一种类型的厨房并将其加扰以制作类型样本书。 它不仅经历了五个世纪，而且经历了电子排版的飞跃，基本保持不变。 它在 1960 年代随着包含 Lorem Ipsum 段落的 Letraset 表的发布而流行起来，最近还随着 Aldus PageMaker 等桌面出版软件（包括 Lorem Ipsum 的版本）而普及.`,
  },
  docs: {
    hi: `你好`,
    description: `需要帮忙？\n 请查看我们的文档.`,
    documentation: `文件`,
  },
  app: `应用程序`,
  all: `全部`,
  user: `用户`,
  list: `列表`,
  edit: `编辑`,
  view: `看法`,
  shop: `商店`,
  blog: `博客`,
  post: `邮政`,
  mail: `电子邮件`,
  chat: `聊天`,
  cards: `牌`,
  posts: `帖子`,
  create: `创建`,
  kanban: `登机`,
  general: `一般的`,
  banking: `银行业`,
  booking: `预订`,
  profile: `轮廓`,
  account: `帐户`,
  product: `产品`,
  invoice: `发票`,
  details: `细节`,
  checkout: `退房`,
  calendar: `日历`,
  analytics: `分析`,
  ecommerce: `电子商务`,
  management: `管理`,
  menu_level_1: `菜单级别 1`,
  menu_level_2: `菜单级别 2`,
  menu_level_3: `菜单级别 3`,
  item_disabled: `项目已禁用`,
  item_label: `项目标签`,
  item_caption: `项目标题`,
  other_cases: `其他情况`,
  item_by_roles: `按角色逐项`,
  only_admin_can_see_this_item: `只有管理员可以看到这个项目`,
  category: `类别`,
  price: `价格`,
  settings: `设置`,
  logout: `登出`,
  overview: `概述`,
  income_resources: `收入资源`,
  wallets: `钱包`,
  entities: `实体`,
  transactions: `交易`,
  assets: `资产`,
  name: `姓名`,
  label: `标签`,
  owner: `所有者`,
  number: `数字`,
  no_number: `没有号码`,
  add: `添加`,
  add_new: `添加`,
  select:`选择`,
  change: `改变`,
  update: `更新`,
  view_more: `查看更多`,
  exchange: `交换`,
  date: `日期`,
  amount: `数量`,
  status: `地位`,
  cancel: `取消`,
  delete: `删除`,
  not_set: `没有设置`,
  attachment: {
    attachment: `附件`,
    remove_all: `移除所有`,
    upload_files: `上传文件`,
    drop_or_select_file: `删除或选择文件`,
    drop_files_here_or_click: `将文件拖放到此处或单击`,
    browse: `浏览`,
    thorough_your_machine: `彻底检查你的机器`
  },
  description: {
    description: `描述`,
    add_description: `添加描述`,
    title: `标题`,
    value: `价值`
  },
  canvas: {
    canvas: `帆布`,
    canvases: `画布`,
    your_canvases: `你的画布`,
    add_canvas: `添加画布`,
    create_new_canvas: `创建新画布`,
    update_photo: `更新照片`,
    upload_photo: `上传照片`,
    no_canvas: `选择画布`
  },
  entity: {
    entity: `实体`,
    entities: `实体`,
    all_entities: `所有实体`,
    your_entities: `你的实体`,
    entity_name: `实体名称`,
    new_entity: `新实体`,
    add_new_entity: `添加新实体`,
    update_entity: `更新实体`,
    no_entities: `没有实体`,
    use_default: `使用此实体作为默认值`,
    no_default_entity: `没有默认实体`,
    default_entity: `默认实体`
  },
  income_resource: {
    income_resource: `收入来源`,
    income_resources: `收入资源`,
    all_income_resources: `所有收入来源`,
    income_method: `收入法`,
    income_unit: `收入单位`,
    add_new_income_resource: `添加新的收入资源`,
    new_income_resource: `新的收入来源`,
    update_income_resource: `更新收入资源`,
    no_income_resources: `没有收入来源`
  },
  income_transaction: {
    income_transactions: `收入`,
    income_transaction: `收入`,
    income_transaction_number: `收入编号`,
    all_income_transactions: `所有收入`,
    new_income_transaction: `新收入`,
    no_income_transactions: `没有收入`,
    income_transaction_list: `收入清单`,
    income_transaction_search_palceholder: `客户搜索或收入编号...`
  },
  spent_transaction: {
    spent_transactions: `已花费的交易`,
    spent_transaction: `已花费的交易`,
    spent_transaction_number: `已用交易号`,
    all_spent_transactions: `所有花费的交易`,
    new_spent_transaction: `新花费的交易`,
    no_spent_transactions: `没有花费的交易`,
    spent_transaction_list: `已用交易清单`,
    spent_transaction_search_palceholder: `搜索客户或花费的交易号...`
  },
  converted_transaction: {
    convert: `兑换`,
    add_convert: `添加转换`,
    add_convert_description: `添加转换说明`,
    origin_amount: `来源金额`,
    destination_amount: `目的地金额`,
    exchange_rate: `汇率`,
    transaction_fee: `手续费`,
    fee_currency: `费用币种`
  },
  transaction: {
    overall: `全面的`,
    paid: `有薪酬的`,
    unpaid: `未付`,
    overdue: `逾期`,
    service_type: `服务类型`,
    start_date: `开始日期`,
    end_date: `结束日期`,
    client: `客户`,
    origin_name: `产地名称`,
    destination_name: `目的地名称`,
    paid_date: `支付日期`,
    to_be_paid_date: `付款日期`,
    amount: `数量`,
    status: `地位`,
    from: '从',
    to: '至',
    add_transaction_description: `添加交易描述`,
  },
  expense: {
    expenses: `花费`,
    all_expenses: `所有费用`,
    add_new_expense: `添加新费用`,
    new_expense: `新费用`,
    update_expense: `更新费用`,
    no_expenses: `没有费用`
  },
  currency: {
    currency: `货币`,
    currencies: `货币`,
    all_currencies: `所有货币`,
    add_new_currency: `添加新货币`,
    new_currency: `新货币`,
    update_currency: `更新货币`,
    no_currencies: `没有货币`
  },
  asset: {
    asset: `资产`,
    assets: `资产`,
    all_assets: `所有资产`,
    new_asset: `新资产`,
    add_new_asset: `添加新资产`,
    update_asset:  `更新资产`
  },
  wallet: {
    wallet: `钱包`,
    wallets: `钱包`,
    all_wallets: `所有钱包`,
    your_wallets: `你的钱包`,
    wallet_name: `钱包名称`,
    new_wallet: `新钱包`,
    add_new_wallet: `添加新钱包`,
    update_wallet: `更新钱包`,
    no_wallets: `没有找到钱包`,
    use_default: `默认使用此钱包`,
    no_default_wallet: `没有默认钱包`,
    default_wallet: `默认钱包`
  },
  chart: {
    chart: `图表`,
    transactionType: `交易类型`,
    date: {
      date: `日期`,
      today: `今天`,
      this_week: `本星期`,
      last_week: `上个星期`,
      this_month: `这个月`,
      last_month: `上个月`,
      this_year: `今年`,
      last_year: `去年`,
      custom: `风俗`,
    },
  },
  breadcrumb: {
    dashboard: `仪表板`,
    user: `用户`,
    income_transactions: `收入`,
    spent_transactions: `已花费的交易`,
  },
  prompt: {
    prompt_default_message: `你确定要删除吗？`,
    prompt_default_cancel: `不`,
    prompt_default_confirm: `是的`,
    prompt_delete_asset: `确定要删除此资产吗？`,
    prompt_delete_entity: `确定要删除此实体吗？`,
    prompt_delete_expense: `您确定要删除此费用吗？`,
    prompt_delete_income_resource: `您确定要删除此收入资源吗？`,
    prompt_delete_wallet: `您确定要删除此钱包吗?`,
  },
  expenses: `花费`,
  incomes: `收入`,
  expense_mo: `费用/月`,
  income_mo: `收入/月`,
  members: `成员`,
  monthly_transaction_overview: `每月交易概览`,
};

export default cn;
