// import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch, RootState, useSelector } from '../store';
// utils
import { PATH_API } from 'src/routes/paths';
import { Canvas } from '../../@types/canvas';
import { IncomeTransaction } from 'src/@types/income-transaction';
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

export type CanvasState = {
  isLoading: boolean;
  error: boolean;
  canvas: Canvas;
  incomeTransactions: {
    overall: {
      totalItems: number;
	    totalAmount: number;
      items: IncomeTransaction[]
    };
    paid: {
      totalItems: number;
	    totalAmount: number;
      items: IncomeTransaction[]
    };
    unpaid: {
      totalItems: number;
	    totalAmount: number;
      items: IncomeTransaction[]
    };
    overdue: {
      totalItems: number;
	    totalAmount: number;
      items: IncomeTransaction[]
    };
  };
  spentTransactions: {
    overall: {
      totalItems: number;
	    totalAmount: number;
      items: IncomeTransaction[]
    };
    paid: {
      totalItems: number;
	    totalAmount: number;
      items: IncomeTransaction[]
    };
    unpaid: {
      totalItems: number;
	    totalAmount: number;
      items: IncomeTransaction[]
    };
    overdue: {
      totalItems: number;
	    totalAmount: number;
      items: IncomeTransaction[]
    };
  };
};

type GetIncomeTransactionsReturnType = () => Promise<void>;

type GetSpentTransactionsReturnType = () => Promise<void>;

const initialState: CanvasState = {
  isLoading: false,
  error: false,
  canvas: {} as Canvas,
  incomeTransactions: {
    overall: {
      totalItems: 0,
	    totalAmount: 0,
      items: []
    },
    paid: {
      totalItems: 0,
	    totalAmount: 0,
      items: []
    },
    unpaid: {
      totalItems: 0,
	    totalAmount: 0,
      items: []
    },
    overdue: {
      totalItems: 0,
	    totalAmount: 0,
      items: []
    }
  },
  spentTransactions: {
    overall: {
      totalItems: 0,
	    totalAmount: 0,
      items: []
    },
    paid: {
      totalItems: 0,
	    totalAmount: 0,
      items: []
    },
    unpaid: {
      totalItems: 0,
	    totalAmount: 0,
      items: []
    },
    overdue: {
      totalItems: 0,
	    totalAmount: 0,
      items: []
    }
  }
};


const slice = createSlice({
  name: 'canvas',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state: CanvasState) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state: CanvasState, action: { payload: any }) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CANVAS
    getCanvasSuccess(state: CanvasState, action: any) {
      state.isLoading = false;
      state.canvas = action.payload;
    },

    // GET OVERALL INCOME TRANSACTIONS
    getOverallIncomeTransactionsSuccess(state: CanvasState, action: any) {
      state.isLoading = false;
      state.incomeTransactions.overall.totalAmount = action.payload.totalAmount
      state.incomeTransactions.overall.totalItems = action.payload.totalItems
      state.incomeTransactions.overall.items = action.payload.incomeTransactions;
    },

    // GET PAID INCOME TRANSACTIONS
    getPaidIncomeTransactionsSuccess(state: CanvasState, action: any) {
      state.isLoading = false;
      state.incomeTransactions.paid.totalAmount = action.payload.totalAmount
      state.incomeTransactions.paid.totalItems = action.payload.totalItems
      state.incomeTransactions.paid.items = action.payload.incomeTransactions;
    },

    // GET UNPAID INCOME TRANSACTIONS
    getUnpaidIncomeTransactionsSuccess(state: CanvasState, action: any) {
      state.isLoading = false;
      state.incomeTransactions.unpaid.totalAmount = action.payload.totalAmount
      state.incomeTransactions.unpaid.totalItems = action.payload.totalItems
      state.incomeTransactions.unpaid.items = action.payload.incomeTransactions;
    },

    // GET OVERDUE INCOME TRANSACTIONS
    getOverdueIncomeTransactionsSuccess(state: CanvasState, action: any) {
      state.isLoading = false;
      state.incomeTransactions.overdue.totalAmount = action.payload.totalAmount
      state.incomeTransactions.overdue.totalItems = action.payload.totalItems
      state.incomeTransactions.overdue.items = action.payload.incomeTransactions;
    },

    // GET OVERALL Spent TRANSACTIONS
    getOverallSpentTransactionsSuccess(state: CanvasState, action: any) {
      state.isLoading = false;
      state.spentTransactions.overall.totalAmount = action.payload.totalAmount
      state.spentTransactions.overall.totalItems = action.payload.totalItems
      state.spentTransactions.overall.items = action.payload.spentTransactions;
    },

    // GET PAID Spent TRANSACTIONS
    getPaidSpentTransactionsSuccess(state: CanvasState, action: any) {
      state.isLoading = false;
      state.spentTransactions.paid.totalAmount = action.payload.totalAmount
      state.spentTransactions.paid.totalItems = action.payload.totalItems
      state.spentTransactions.paid.items = action.payload.spentTransactions;
    },

    // GET UNPAID Spent TRANSACTIONS
    getUnpaidSpentTransactionsSuccess(state: CanvasState, action: any) {
      state.isLoading = false;
      state.spentTransactions.unpaid.totalAmount = action.payload.totalAmount
      state.spentTransactions.unpaid.totalItems = action.payload.totalItems
      state.spentTransactions.unpaid.items = action.payload.spentTransactions;
    },

    // GET OVERDUE Spent TRANSACTIONS
    getOverdueSpentTransactionsSuccess(state: CanvasState, action: any) {
      state.isLoading = false;
      state.spentTransactions.overdue.totalAmount = action.payload.totalAmount
      state.spentTransactions.overdue.totalItems = action.payload.totalItems
      state.spentTransactions.overdue.items = action.payload.spentTransactions;
    },
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCanvas(canvasId: number) {
  return async () => {
    try {
      const response = await axios.get(PATH_API.user.canvas(String(canvasId)));
      dispatch(slice.actions.getCanvasSuccess(response.data.canvas));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

/**
 * 
 * @param {number} canvasId 
 * @param {number} rpp Rows Per Page
 * @param {number} pn Page Number
 * @returns {GetIncomeTransactionsReturnType}
 */
export function getOverallIncomeTransactions(canvasId: number, rpp: number, pn: number): GetIncomeTransactionsReturnType {
  return async () => {
    try {

      const response = await axios.get(PATH_API.incomeTransaction.canvasOverallTransactions(`/overall/${canvasId}/${rpp}/${pn}`));
      dispatch(slice.actions.getOverallIncomeTransactionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

/**
 * 
 * @param {number} canvasId 
 * @param {number} rpp Rows Per Page
 * @param {number} pn Page Number
 * @returns {GetIncomeTransactionsReturnType}
 */
export function getPaidIncomeTransactions(canvasId: number, rpp: number, pn: number): GetIncomeTransactionsReturnType {
  return async () => {
    try {

      const response = await axios.get(PATH_API.incomeTransaction.canvasOverallTransactions(`/paid/${canvasId}/${rpp}/${pn}`));
      dispatch(slice.actions.getPaidIncomeTransactionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

/**
 * 
 * @param {number} canvasId 
 * @param {number} rpp Rows Per Page
 * @param {number} pn Page Number
 * @returns {GetIncomeTransactionsReturnType}
 */
export function getUnpaidIncomeTransactions(canvasId: number, rpp: number, pn: number): GetIncomeTransactionsReturnType {
  return async () => {
    try {

      const response = await axios.get(PATH_API.incomeTransaction.canvasOverallTransactions(`/unpaid/${canvasId}/${rpp}/${pn}`));
      dispatch(slice.actions.getUnpaidIncomeTransactionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

/**
 * 
 * @param {number} canvasId 
 * @param {number} rpp Rows Per Page
 * @param {number} pn Page Number
 * @returns {GetIncomeTransactionsReturnType}
 */
export function getOverdueIncomeTransactions(canvasId: number, rpp: number, pn: number): GetIncomeTransactionsReturnType {
  return async () => {
    try {

      const response = await axios.get(PATH_API.incomeTransaction.canvasOverallTransactions(`/overdue/${canvasId}/${rpp}/${pn}`));
      dispatch(slice.actions.getOverdueIncomeTransactionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

/**
 * 
 * @param {number} canvasId 
 * @param {number} rpp Rows Per Page
 * @param {number} pn Page Number
 * @returns {GetIncomeTransactionsReturnType}
 */
export function getAllIncomeTransactions(canvasId: number, rpp: number, pn: number): GetIncomeTransactionsReturnType {
  return async () => {
    dispatch(getOverallIncomeTransactions(canvasId, rpp, pn));
    dispatch(getPaidIncomeTransactions(canvasId, rpp, pn));
    dispatch(getUnpaidIncomeTransactions(canvasId, rpp, pn));
    dispatch(getOverdueIncomeTransactions(canvasId, rpp, pn));
  };
}

// ----------------------------------------------------------------------

/**
 * 
 * @param {number} canvasId 
 * @param {number} rpp Rows Per Page
 * @param {number} pn Page Number
 * @returns {GetSpentTransactionsReturnType}
 */
 export function getOverallSpentTransactions(canvasId: number, rpp: number, pn: number): GetSpentTransactionsReturnType {
  return async () => {
    try {

      const response = await axios.get(PATH_API.spentTransaction.canvasOverallTransactions(`/overall/${canvasId}/${rpp}/${pn}`));
      dispatch(slice.actions.getOverallSpentTransactionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

/**
 * 
 * @param {number} canvasId 
 * @param {number} rpp Rows Per Page
 * @param {number} pn Page Number
 * @returns {GetSpentTransactionsReturnType}
 */
export function getPaidSpentTransactions(canvasId: number, rpp: number, pn: number): GetSpentTransactionsReturnType {
  return async () => {
    try {

      const response = await axios.get(PATH_API.spentTransaction.canvasOverallTransactions(`/paid/${canvasId}/${rpp}/${pn}`));
      dispatch(slice.actions.getPaidSpentTransactionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

/**
 * 
 * @param {number} canvasId 
 * @param {number} rpp Rows Per Page
 * @param {number} pn Page Number
 * @returns {GetSpentTransactionsReturnType}
 */
export function getUnpaidSpentTransactions(canvasId: number, rpp: number, pn: number): GetSpentTransactionsReturnType {
  return async () => {
    try {

      const response = await axios.get(PATH_API.spentTransaction.canvasOverallTransactions(`/unpaid/${canvasId}/${rpp}/${pn}`));
      dispatch(slice.actions.getUnpaidSpentTransactionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

/**
 * 
 * @param {number} canvasId 
 * @param {number} rpp Rows Per Page
 * @param {number} pn Page Number
 * @returns {GetSpentTransactionsReturnType}
 */
export function getOverdueSpentTransactions(canvasId: number, rpp: number, pn: number): GetSpentTransactionsReturnType {
  return async () => {
    try {

      const response = await axios.get(PATH_API.spentTransaction.canvasOverallTransactions(`/overdue/${canvasId}/${rpp}/${pn}`));
      dispatch(slice.actions.getOverdueSpentTransactionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

/**
 * 
 * @param {number} canvasId 
 * @param {number} rpp Rows Per Page
 * @param {number} pn Page Number
 * @returns {GetSpentTransactionsReturnType}
 */
export function getAllSpentTransactions(canvasId: number, rpp: number, pn: number): GetSpentTransactionsReturnType {
  return async () => {
    dispatch(getOverallSpentTransactions(canvasId, rpp, pn));
    dispatch(getPaidSpentTransactions(canvasId, rpp, pn));
    dispatch(getUnpaidSpentTransactions(canvasId, rpp, pn));
    dispatch(getOverdueSpentTransactions(canvasId, rpp, pn));
  };
}