// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const vn = {
  demo: {
    title: `Vietnamese`,
    introduction: `Lorem Ipsum chỉ đơn giản là văn bản giả của ngành in ấn và sắp chữ. Lorem Ipsum đã trở thành văn bản giả tiêu chuẩn của ngành kể từ những năm 1500, khi một nhà in không xác định lấy một dãy loại và xáo trộn nó để tạo thành một cuốn sách mẫu. Nó đã tồn tại không chỉ năm thế kỷ, mà còn là bước nhảy vọt trong lĩnh vực sắp chữ điện tử, về cơ bản vẫn không thay đổi. Nó được phổ biến vào những năm 1960 với việc phát hành các tờ Letraset chứa các đoạn Lorem Ipsum, và gần đây hơn là với phần mềm xuất bản trên máy tính để bàn như Aldus PageMaker bao gồm các phiên bản của Lorem Ipsum.`,
  },
  docs: {
    hi: `Chào`,
    description: `Cần giúp đỡ？\n Vui lòng xem tài liệu của chúng tôi.`,
    documentation: `tài liệu`,
  },
  app: `ứng dụng`,
  all: `tất cả các`,
  user: `người dùng`,
  list: `danh sách`,
  edit: `chỉnh sửa`,
  view: `lượt xem`,
  shop: `cửa hàng`,
  blog: `blog`,
  post: `bài viết`,
  mail: `thư`,
  chat: `trò chuyện`,
  cards: `thẻ`,
  posts: `danh sách bài viết`,
  create: `tạo`,
  kanban: `bảng`,
  general: `chung`,
  banking: `ngân hàng`,
  booking: `đặt phòng`,
  profile: `hồ sơ`,
  account: `tải khoản`,
  product: `sản phẩm`,
  invoice: `hóa đơn`,
  details: `chi tiết`,
  checkout: `thanh toán`,
  calendar: `lịch`,
  analytics: `phân tích`,
  ecommerce: `thương mại điện tử`,
  management: `quản lý`,
  menu_level_1: `menu cấp 1`,
  menu_level_2: `menu cấp 2`,
  menu_level_3: `menu cấp 3`,
  item_disabled: `mục vô hiệu`,
  item_label: `mục nhãn`,
  item_caption: `mục chú thích`,
  other_cases: `Trường hợp khác`,
  item_by_roles: `mục theo vai trò`,
  only_admin_can_see_this_item: `Chỉ có quản trị viên mới có thể nhìn thấy mục này`,
  category: `thể loại`,
  price: `giá bán`,
  settings: `cài đặt`,
  logout: `đăng xuất`,
  overview: `tổng quát`,
  income_resources: `nguồn thu nhập`,
  wallets: `ví tiền`,
  entities: `thực thể`,
  transactions: `giao dịch`,
  assets: `tài sản`,
  name: `Tên`,
  label: `nhãn mác`,
  owner: `chủ nhân`,
  number: `con số`,
  no_number: `không có số`,
  add: `cộng`,
  add_new: `thêm mới`,
  select:`lựa chọn`,
  change: `biến đổi`,
  update: `cập nhật`,
  view_more: `xem thêm`,
  exchange: `trao đổi`,
  date: `ngày`,
  amount: `số lượng`,
  status: `trạng thái`,
  cancel: `sự hủy bỏ`,
  delete: `xóa bỏ`,
  not_set: `không được thiết lập`,
  attachment: {
    attachment: `tập tin đính kèm`,
    remove_all: `xoá bỏ hết`,
    upload_files: `tải tệp lên`,
    drop_or_select_file: `thả hoặc chọn tệp`,
    drop_files_here_or_click: `thả tệp vào đây hoặc nhấp vào`,
    browse: `duyệt qua`,
    thorough_your_machine: `kỹ lưỡng máy của bạn`
  },
  description: {
    description: `mô tả`,
    add_description: `thêm mô tả`,
    title: `Tiêu đề`,
    value: `giá trị`
  },
  canvas: {
    canvas: `Tranh sơn dầu`,
    canvases: `vải bạt`,
    your_canvases: `Những bức tranh của bạn`,
    add_canvas: `thêm canvas`,
    create_new_canvas: `tạo canvas mới`,
    update_photo: `cập nhật ảnh`,
    upload_photo: `tải ảnh lên`,
    no_canvas: `chọn canvas`
  },
  entity: {
    entity: `thực thể`,
    entities: `thực thể`,
    all_entities: `tất cả các thực thể`,
    your_entities: `thực thể của bạn`,
    entity_name: `tên thực thể`,
    new_entity: `thực thể mới`,
    add_new_entity: `thêm thực thể mới`,
    update_entity: `cập nhật thực thể`,
    no_entities: `không có thực thể`,
    use_default: `sử dụng thực thể này làm mặc định`,
    no_default_entity: `không có thực thể mặc định`,
    default_entity: `thực thể mặc định`
  },
  income_resource: {
    income_resource: `nguồn thu nhập`,
    income_resources: `nguồn thu nhập`,
    all_income_resources: `tất cả các nguồn thu nhập`,
    income_method: `phương pháp thu nhập`,
    income_unit: `đơn vị thu nhập`,
    add_new_income_resource: `thêm nguồn thu nhập mới`,
    new_income_resource: `nguồn thu nhập mới`,
    update_income_resource: `cập nhật nguồn thu nhập`,
    no_income_resources: `không có nguồn thu nhập`
  },
  income_transaction: {
    income_transactions: `Doanh thu`,
    income_transaction: `Thu nhập`,
    income_transaction_number: `Số doanh thu`,
    all_income_transactions: `Tất cả thu nhập`,
    new_income_transaction: `Thu nhập mới`,
    no_income_transactions: `Không có thu nhập`,
    income_transaction_list: `Danh sách thu nhập`,
    income_transaction_search_palceholder: `Tìm kiếm khách hàng hoặc số doanh thu...`
  },
  spent_transaction: {
    spent_transactions: `giao dịch đã chi tiêu`,
    spent_transaction: `giao dịch đã chi tiêu`,
    spent_transaction_number: `số giao dịch đã chi tiêu`,
    all_spent_transactions: `tất cả các giao dịch đã chi tiêu`,
    new_spent_transaction: `giao dịch đã chi tiêu mới`,
    no_spent_transactions: `không có giao dịch đã chi tiêu`,
    spent_transaction_list: `danh sách giao dịch đã chi tiêu`,
    spent_transaction_search_palceholder: `khách hàng tìm kiếm hoặc số giao dịch đã chi tiêu...`
  },
  converted_transaction: {
    convert: `đổi`,
    add_convert: `thêm chuyển đổi`,
    add_convert_description: `thêm mô tả chuyển đổi`,
    origin_amount: `số tiền gốc`,
    destination_amount: `số tiền đích`,
    exchange_rate: `tỷ giá`,
    transaction_fee: `Phí giao dịch`,
    fee_currency: `tiền phí`
  },
  transaction: {
    overall: `tổng thể`,
    paid: `trả`,
    unpaid: `Chưa thanh toán`,
    overdue: `quá hạn`,
    service_type: `loại dịch vụ`,
    start_date: `ngày bắt đầu`,
    end_date: `ngày cuối`,
    client: `khách hàng`,
    origin_name: `tên gốc`,
    destination_name: `Tên điểm đến`,
    paid_date: `Ngày thanh toán`,
    to_be_paid_date: `ngày được thanh toán`,
    amount: `số lượng`,
    status: `trạng thái`,
    from: 'từ',
    to: 'đến',
    add_transaction_description: `thêm mô tả giao dịch`,
  },
  expense: {
    expenses: `chi phí`,
    all_expenses: `mọi chi phí`,
    add_new_expense: `thêm chi phí mới`,
    new_expense: `chi phí mới`,
    update_expense: `cập nhật chi phí`,
    no_expenses: `không có chi phí`
  },
  currency: {
    currency: `tiền tệ`,
    currencies: `tiền tệ`,
    all_currencies: `tất cả các loại tiền tệ`,
    add_new_currency: `thêm tiền tệ mới`,
    new_currency: `tiền tệ mới`,
    update_currency: `cập nhật tiền tệ`,
    no_currencies: `không có tiền tệ`
  },
  asset: {
    asset: `tài sản`,
    assets: `tài sản`,
    all_assets: `tất cả tài sản`,
    new_asset: `tài sản mới`,
    add_new_asset: `thêm tài sản mới`,
    update_asset:  `cập nhật tài sản`
  },
  wallet: {
    wallet: `cái ví`,
    wallets: `ví tiền`,
    all_wallets: `tất cả các ví`,
    your_wallets: `ví của bạn`,
    wallet_name: `tên ví`,
    new_wallet: `ví mới`,
    add_new_wallet: `thêm ví mới`,
    update_wallet: `cập nhật ví`,
    no_wallets: `không tìm thấy ví`,
    use_default: `sử dụng ví này làm mặc định`,
    no_default_wallet: `không có ví mặc định`,
    default_wallet: `ví mặc định`
  },
  chart: {
    chart: `đồ thị`,
    transactionType: `Loại giao dịch`,
    date: {
      date: `ngày`,
      today: `hôm nay`,
      this_week: `tuần này`,
      last_week: `tuần trước`,
      this_month: `tháng này`,
      last_month: `tháng trước`,
      this_year: `Năm nay`,
      last_year: `năm ngoái`,
      custom: `tập quán`,
    },
  },
  breadcrumb: {
    dashboard: `bảng điều khiển`,
    user: `người sử dụng`,
    income_transactions: `Doanh thu`,
    spent_transactions: `giao dịch đã chi tiêu`,
  },
  prompt: {
    prompt_default_message: `Bạn có chắc chắn muốn xóa?`,
    prompt_default_cancel: `không`,
    prompt_default_confirm: `Vâng`,
    prompt_delete_asset: `bạn có chắc chắn muốn xóa nội dung này không?`,
    prompt_delete_entity: `Bạn có chắc chắn muốn xóa thực thể này không?`,
    prompt_delete_expense: `Bạn có chắc chắn muốn xóa khoản chi phí này không?`,
    prompt_delete_income_resource: `Bạn có chắc chắn muốn xóa tài nguyên thu nhập này không?`,
    prompt_delete_wallet: `Bạn có chắc chắn muốn xóa ví này không?`,
  },
  expenses: `Chi phí`,
  incomes: `Thu nhập`,
  expense_mo: `Chi phí / tháng`,
  income_mo: `Thu nhập / tháng`,
  members: `Các thành viên`,
  monthly_transaction_overview: `Tổng quan về giao dịch hàng tháng`,
};

export default vn;
