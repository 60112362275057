// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const fr = {
  demo: {
    title: `France`,
    introduction: `Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker.`,
  },
  docs: {
    hi: `Bonjour`,
    description: `Besoin d'aide? \n Consultez notre documentation.`,
    documentation: `documentation`,
  },
  app: `application`,
  all: `tout`,
  user: `utilisateur`,
  list: `liste`,
  edit: `éditer`,
  view: `voir`,
  shop: `boutique`,
  blog: `blog`,
  post: `poste`,
  mail: `e-mail`,
  chat: `discuter`,
  cards: `cartes`,
  posts: `des postes`,
  create: `créer`,
  kanban: `kanban`,
  general: `général`,
  banking: `bancaire`,
  booking: `réservation`,
  profile: `profil`,
  account: `compte`,
  product: `produit`,
  invoice: `facture`,
  details: `détail`,
  checkout: `vérifier`,
  calendar: `calendrier`,
  analytics: `analytique`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `niveau menu 1`,
  menu_level_2: `niveau menu 2`,
  menu_level_3: `niveau menu 3`,
  item_disabled: `élément désactivé`,
  item_label: `étiquette de l'article`,
  item_caption: `légende de l'article`,
  other_cases: `autres cas`,
  item_by_roles: `élément par rôles`,
  only_admin_can_see_this_item: `seul l'administrateur peut voir cet élément`,
  category: `Catégorie`,
  price: `le prix`,
  settings: `réglages`,
  logout: `Se déconnecter`,
  overview: `Aperçu`,
  income_resources: `ressources de revenu`,
  wallets: `portefeuilles`,
  entities: `entités`,
  transactions: `transactions`,
  assets: `des atouts`,
  name: `Nom`,
  label: `étiquette`,
  owner: `propriétaire`,
  number: `Numéro`,
  no_number: `pas de numéro`,
  add: `ajouter`,
  add_new: `ajouter nouveau`,
  select:`sélectionner`,
  change: `monnaie`,
  update: `mettre à jour`,
  view_more: `Voir plus`,
  exchange: `échanger`,
  date: `date`,
  amount: `montant`,
  status: `statut`,
  cancel: `annuler`,
  delete: `effacer`,
  not_set: `pas encore défini`,
  attachment: {
    attachment: `attachement`,
    remove_all: `Enlever tout`,
    upload_files: `télécharger des fichiers`,
    drop_or_select_file: `déposer ou sélectionner un fichier`,
    drop_files_here_or_click: `déposez les fichiers ici ou cliquez`,
    browse: `Feuilleter`,
    thorough_your_machine: `minutieux de votre machine`
  },
  description: {
    description: `la description`,
    add_description: `ajouter une description`,
    title: `titre`,
    value: `évaluer`
  },
  canvas: {
    canvas: `toile`,
    canvases: `toiles`,
    your_canvases: `vos toiles`,
    add_canvas: `ajouter une toile`,
    create_new_canvas: `créer un nouveau canevas`,
    update_photo: `mettre à jour la photo`,
    upload_photo: `envoyer la photo`,
    no_canvas: `sélectionner la toile`
  },
  entity: {
    entity: `entité`,
    entities: `entités`,
    all_entities: `toutes les entités`,
    your_entities: `vos entités`,
    entity_name: `nom de l'entité`,
    new_entity: `nouvelle entité`,
    add_new_entity: `ajouter une nouvelle entité`,
    update_entity: `mettre à jour l'entité`,
    no_entities: `aucune entité`,
    use_default: `utiliser cette entité par défaut`,
    no_default_entity: `pas d'entité par défaut`,
    default_entity: `entité par défaut`
  },
  income_resource: {
    income_resource: `ressource de revenu`,
    income_resources: `ressources de revenu`,
    all_income_resources: `toutes les sources de revenu`,
    income_method: `méthode de revenu`,
    income_unit: `unité de revenu`,
    add_new_income_resource: `ajouter une nouvelle ressource de revenu`,
    new_income_resource: `nouvelle source de revenu`,
    update_income_resource: `mettre à jour la ressource de revenu`,
    no_income_resources: `pas de ressources de revenus`
  },
  income_transaction: {
    income_transactions: `Revenus`,
    income_transaction: `Revenu`,
    income_transaction_number: `Chiffre d'affaires`,
    all_income_transactions: `Tous les revenus`,
    new_income_transaction: `Nouveaux revenus`,
    no_income_transactions: `Aucun revenu`,
    income_transaction_list: `Liste des revenus`,
    income_transaction_search_palceholder: `Recherche de client ou numéro de revenu...`
  },
  spent_transaction: {
    spent_transactions: `transactions passées`,
    spent_transaction: `transaction passée`,
    spent_transaction_number: `numéro de transaction dépensé`,
    all_spent_transactions: `toutes les transactions dépensées`,
    new_spent_transaction: `nouvelle transaction dépensée`,
    no_spent_transactions: `aucune transaction dépensée`,
    spent_transaction_list: `liste des transactions dépensées`,
    spent_transaction_search_palceholder: `rechercher un client ou un numéro de transaction dépensé...`
  },
  converted_transaction: {
    convert: `convertir`,
    add_convert: `ajouter convertir`,
    add_convert_description: `ajouter une description de conversion`,
    origin_amount: `montant d'origine`,
    destination_amount: `montant à destination`,
    exchange_rate: `taux de change`,
    transaction_fee: `frais de transaction`,
    fee_currency: `devise des frais`
  },
  transaction: {
    overall: `globalement`,
    paid: `payé`,
    unpaid: `non payé`,
    overdue: `en retard`,
    service_type: `type de service`,
    start_date: `date de début`,
    end_date: `date de fin`,
    client: `client`,
    origin_name: `nom d'origine`,
    destination_name: `nom de destination`,
    paid_date: `la date de paiement`,
    to_be_paid_date: `date à payer`,
    amount: `montant`,
    status: `statut`,
    from: 'de',
    to: 'à',
    add_transaction_description: `ajouter la description de la transaction`,
  },
  expense: {
    expenses: `dépenses`,
    all_expenses: `toutes dépenses`,
    add_new_expense: `ajouter une nouvelle dépense`,
    new_expense: `nouvelle dépense`,
    update_expense: `mise à jour des dépenses`,
    no_expenses: `pas de frais`
  },
  currency: {
    currency: `devise`,
    currencies: `devises`,
    all_currencies: `toutes les devises`,
    add_new_currency: `ajouter une nouvelle devise`,
    new_currency: `nouvelle devise`,
    update_currency: `mettre à jour la devise`,
    no_currencies: `pas de devis`
  },
  asset: {
    asset: `actif`,
    assets: `des atouts`,
    all_assets: `tous les actifs`,
    new_asset: `nouvel atout`,
    add_new_asset: `ajouter un nouvel actif`,
    update_asset:  `mettre à jour l'actif`
  },
  wallet: {
    wallet: `porte monnaie`,
    wallets: `portefeuilles`,
    all_wallets: `tous les portefeuilles`,
    your_wallets: `vos portefeuilles`,
    wallet_name: `nom du portefeuille`,
    new_wallet: `nouveau portefeuille`,
    add_new_wallet: `ajouter un nouveau portefeuille`,
    update_wallet: `mettre à jour le portefeuille`,
    no_wallets: `aucun portefeuille trouvé`,
    use_default: `utilisez ce portefeuille par défaut`,
    no_default_wallet: `pas de portefeuille par défaut`,
    default_wallet: `portefeuille par défaut`
  },
  chart: {
    chart: `graphique`,
    transactionType: `type de transaction`,
    date: {
      date: `date`,
      today: `aujourd'hui`,
      this_week: `cette semaine`,
      last_week: `La semaine dernière`,
      this_month: `ce mois-ci`,
      last_month: `le mois dernier`,
      this_year: `cette année`,
      last_year: `l'année dernière`,
      custom: `Douane`,
    },
  },
  breadcrumb: {
    dashboard: `tableau de bord`,
    user: `utilisateur`,
    income_transactions: `Revenus`,
    spent_transactions: `transactions passées`,
  },
  prompt: {
    prompt_default_message: `Voulez-vous vraiment supprimer?`,
    prompt_default_cancel: `non`,
    prompt_default_confirm: `oui`,
    prompt_delete_asset: `voulez-vous vraiment supprimer cet élément?`,
    prompt_delete_entity: `Voulez-vous vraiment supprimer cette entité?`,
    prompt_delete_expense: `Voulez-vous vraiment supprimer cette dépense?`,
    prompt_delete_income_resource: `Êtes-vous sûr de vouloir supprimer cette source de revenu?`,
    prompt_delete_wallet: `Êtes-vous sûr de vouloir supprimer ce portefeuille?`,
  },
  expenses: `Dépenses`,
  incomes: `Revenus`,
  expense_mo: `Dépense/mois`,
  income_mo: `Revenu/mois`,
  members: `Membres`,
  monthly_transaction_overview: `Aperçu mensuel des transactions`,
};

export default fr;
